import Vue from 'vue'
import VueRouter from 'vue-router'
import SurveyStores from '../views/Survey-Stores.vue'
import SurveyHO from '../views/Survey-HO.vue'


Vue.use(VueRouter)

const routes = [{
    path: '/Stores',
    name: 'Stores',
    component: SurveyStores,
  },
  {
    path: '/Headoffice',
    name: 'HO',
    component: SurveyHO,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router