<template>
  <div id="app">
    <iframe
      id="typeform-full"
      width="100%"
      height="100%"
      frameborder="0"
      allow="camera; microphone; autoplay; encrypted-media;"
      :src='"https://synermetric.typeform.com/to/DlcFc7d5?company="+company+"&user_id="+uid'
    ></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      company: null,
      uid: null,
    }
  },
  created(){
    console.log('Created')
    this.company = this.$route.query.company;
    this.uid = this.$route.query.id;
  }
}
</script>
